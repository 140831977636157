<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="barcode">
                {{ row.product ? row.product.barcode : "N/A" }}
            </template>
            <template slot-scope="{ row }" slot="product_name_en">
                {{
                    row.product ? row.product.product_name_en : row.product_name
                }}
            </template>
            <template slot-scope="{ row }" slot="repair_status">
                <span class="badge bg-success px-2 fs-11px d-inline-flex">{{
                    row.repair_product_profile_detail_status
                        ? row.repair_product_profile_detail_status.repair_status
                        : ""
                }}</span>
            </template>
            <template slot-scope="{ row }" slot="repaired_by">
                {{
                    row.repaired_by_name
                        ? row.repaired_by_name.employee_name_en
                        : ""
                }}
            </template>
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Object
    },
    computed: {
        resources() {
            return this.row.repair_product_profile_details;
        },
        columns() {
            return [
                {
                    title: this.$t("serviceTicket.barcode"),
                    slot: "barcode"
                },
                {
                    title: this.$t("serviceTicket.productName"),
                    slot: "product_name_en"
                },
                {
                    title: this.$t("serviceTicket.serial"),
                    key: "serial_no"
                },
                {
                    title: this.$t("serviceTicket.qty"),
                    key: "quantity",
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.repairStatus"),
                    slot: "repair_status",
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.repairedBy"),
                    slot: "repaired_by",
                    align: "center"
                },
                {
                    title: this.$t("serviceTicket.remarks"),
                    key: "remarks",
                    align: "center"
                }
            ];
        }
    }
};
</script>
