var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","stripe":""},scopedSlots:_vm._u([{key:"barcode",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.product ? row.product.barcode : "N/A")+" ")]}},{key:"product_name_en",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.product ? row.product.product_name_en : row.product_name)+" ")]}},{key:"repair_status",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"badge bg-success px-2 fs-11px d-inline-flex"},[_vm._v(_vm._s(row.repair_product_profiles_status ? row.repair_product_profiles_status.repair_status : ""))])]}},{key:"repaired_by",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.repaired_by_name ? row.repaired_by_name.employee_name_en : "")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }