var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('serviceTicket.pageTitle'),"breadcrumb":[
        { text: _vm.$t('home'), href: '/' },
        {
            text: _vm.$t('serviceTicket.pageTitle'),
            href: '/admin/service-tickets'
        },
        {
            text: _vm.$t('create'),
            active: true
        }
    ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('form',{staticClass:"form-horizontal"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Info',{staticClass:"tw-p-3 tw-rounded",attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ts-loading-banner',{attrs:{"loading":_vm.loading_detail}},[_c('Detail',{staticClass:"tw-pt-6 tw-rounded",attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)]),_c('div',{staticClass:"row tw-pt-4"},[_c('div',{staticClass:"col-md-12 tw-space-x-4 tw-flex tw-justify-end"},[_c('ts-button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                                    name: 'service-ticket'
                                })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSavePreview.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('savePreview')))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])])],1)],1),_c('Modal',{attrs:{"footer-hide":true,"width":"60%","title":_vm.$t('previewPdf')},model:{value:(_vm.showPdf),callback:function ($$v) {_vm.showPdf=$$v},expression:"showPdf"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src},model:{value:(_vm.waiting),callback:function ($$v) {_vm.waiting=$$v},expression:"waiting"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }