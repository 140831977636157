<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-12 overflow-auto" style="min-height: 300px; max-height: 400px">
                <div class="table-ui-detail">
                    <table>
                        <thead>
                            <tr>
                                <th class="required" style="width: 23%">
                                    {{ $t("repairProductProfile.product") }}
                                </th>
                                <th style="width: 20%">
                                    {{ $t("repairProductProfile.serial") }}
                                </th>
                                <th style="width: 10%">
                                    {{ $t("serviceTicket.uom") }}
                                </th>
                                <th class="required" style="width: 10%">
                                    {{ $t("repairProductProfile.qty") }}
                                </th>
                                <th style="width: 20%">
                                    {{ $t("repairProductProfile.remarks") }}
                                </th>
                                <th class="tw-text-center" style="width: 3%">
                                    <a href="#" class="text-primary" @click.prevent="addNewItem">
                                        <i class="fas fa-plus"></i>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in model.product_detail_list" :key="index">
                                <td>
                                    <input type="text" v-model="item.product_name"
                                        class="form-control only-border-bottom" :class="{
                                            'is-invalid': errors.has(
                                                'product_detail_list.' +
                                                index +
                                                '.product_name'
                                            )
                                        }" :placeholder="$t('repairProductProfile.product')" />
                                    <div class="text-danger" v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                            index +
                                            '.product_name'
                                        )
                                    ">
                                        {{
                                            errors.first(
                                                "product_detail_list." +
                                                index +
                                                ".serial_no"
                                            )
                                        }}
                                    </div>
                                </td>
                                <!-- <td>
                                <Select
                                    v-model="item.product_id"
                                    :filter-by-label="true"
                                    :placeholder="$t('select')"
                                    :clearable="true"
                                    filterable
                                    @on-change="onChangeProduct"
                                >
                                    <Option
                                        v-for="product in productList"
                                        :value="product.product_id"
                                        :key="product.product_id"
                                        :label="product.product_name_en"
                                    >
                                        {{ product.product_name_en }}
                                    </Option>
                                </Select>
                                <div
                                    class="text-danger"
                                    v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                                index +
                                                '.product_id'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            'product_detail_list.' +
                                                index +
                                                '.product_id'
                                        )
                                    }}
                                </div>
                            </td> -->
                                <td>
                                    <input type="text" v-model="item.serial_no" class="form-control only-border-bottom"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_detail_list.' +
                                                index +
                                                '.serial_no'
                                            )
                                        }" :placeholder="$t('repairProductProfile.serial')" />
                                    <div class="text-danger" v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                            index +
                                            '.serial_no'
                                        )
                                    ">
                                        {{
                                            errors.first(
                                                "product_detail_list." +
                                                index +
                                                ".serial_no"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <Select v-model="item.uom_id" :placeholder="$t('select')">
                                        <Option v-for="uom in getUom(item.product_id)" :value="uom.uom_id"
                                            :key="uom.uom_id" :label="uom.uom_name_en">
                                            {{ uom.uom_name_en }}
                                        </Option>
                                    </Select>
                                    <div class="text-danger" v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                            index +
                                            '.uom_id'
                                        )
                                    ">
                                        {{
                                            errors.first(
                                                "product_detail_list." +
                                                index +
                                                ".uom_id"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <input type="number" v-model.number="item.quantity"
                                        class="form-control only-border-bottom" :class="{
                                            'is-invalid': errors.has(
                                                'product_detail_list.' +
                                                index +
                                                '.quantity'
                                            )
                                        }" placeholder="Qty" />
                                    <div class="text-danger" v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                            index +
                                            '.quantity'
                                        )
                                    ">
                                        {{
                                            errors.first(
                                                "product_detail_list." +
                                                index +
                                                ".quantity"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <textarea v-model.number="item.remarks" rows="1"
                                        class="form-control only-border-bottom" :class="{
                                            'is-invalid': errors.has(
                                                'product_detail_list.' +
                                                index +
                                                '.remarks'
                                            )
                                        }" placeholder="remarks" />
                                    <div class="text-danger" v-if="
                                        errors.has(
                                            'product_detail_list.' +
                                            index +
                                            '.remarks'
                                        )
                                    ">
                                        {{
                                            errors.first(
                                                "product_detail_list." +
                                                index +
                                                ".remarks"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-text-center">
                                    <a href="#" class="text-danger" @click.prevent="removeItem(item)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button class="tw-mr-2" @click.prevent="() => $emit('cancel')">
                    {{ $t("cancel") }}
                </ts-button>
                <ts-button v-if="!isUpdate" color="primary" @click.prevent="onSave" :waiting="waiting">{{ $t("save")
                    }}</ts-button>
                <ts-button v-if="isUpdate" color="primary" @click.prevent="onUpdate" :waiting="waiting">{{ $t("update")
                    }}</ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty, clone, debounce } from "lodash";

export default {
    name: "repairProductProfileForm",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                repair_product_id: null,
                repaired_by: null,
                product_detail_list: []
            },
            newItem: {
                repair_product_detail_id: null,
                product_id: null,
                product_name: null,
                serial_no: null,
                uom_id: null,
                quantity: null,
                repaired_by: null,
                completion_date: null,
                remarks: null
            }
        };
    },
    computed: {
        ...mapState("customerService/repairProductProfile", [
            "formViewDatas",
            "edit_data"
        ]),
        ...mapGetters("customerService/repairProductProfile", [
            "getUom",
            "isDefaultUom"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        customerList() {
            return this.formViewDatas.customer;
        },
        employeeList() {
            return this.formViewDatas.employee;
        },
        ticketStatusList() {
            return this.formViewDatas.serviceTicketStatus;
        },
        productList() {
            return this.formViewDatas.product;
        }
    },
    methods: {
        ...mapActions("customerService/repairProductProfile", [
            "getFormViewModel"
        ]),
        async fetchResources() {
            this.loading = true;
            await this.getFormViewModel({ params: {}, index: undefined });
            this.loading = false;
        },
        onChangeProduct(value) {
            let uom_id = this.isDefaultUom(value);
            this.model.product_detail_list.find(
                el => el.product_id == value
            ).uom_id = uom_id;
            return value;
        },
        onChangeServiceDate(date) {
            this.model.service_date = date;
            // this.model.expected_completion_date = moment(
            //     this.model.service_date,
            //     'DD-MM-YYYY'
            // )
            //     .add(3, 'D')
            //     .format('DD-MM-YYYY')
        },
        onChangeTicketOwner(value) {
            if (value) {
                this.model.ticket_status = 2;
            } else {
                this.model.ticket_status = 1;
            }
        },
        addNewItem() {
            this.model.product_detail_list.push(clone(this.newItem));
        },
        removeItem(item) {
            this.model.product_detail_list.splice(
                this.model.product_detail_list.indexOf(item),
                1
            );
        },
        handleSearchCustomer: debounce(function (value) {
            this.loading = true;
            this.getFormViewModel({
                index: "customer",
                params: {
                    fnName: "customer",
                    search: value
                }
            }).finally(() => (this.loading = false));
        }, 800),
        handleSearchEmployee: debounce(function (value) {
            this.loading = true;
            this.getFormViewModel({
                index: "employee",
                params: {
                    fnName: "employee",
                    search: value
                }
            }).finally(() => (this.loading = false));
        }, 800),
        handleSearchProduct: debounce(function (value) {
            this.loading = true;
            this.getFormViewModel({
                index: "product",
                params: {
                    fnName: "product",
                    search: value
                }
            }).finally(() => (this.loading = false));
        }, 800),
        onSave() {
            this.waiting = true;
            this.$store
                .dispatch(
                    "customerService/repairProductProfile/store",
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("customerService/repairProductProfile/update", {
                    id: this.model.repair_product_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.model.repair_product_id = null;
            this.model.product_detail_list = [];
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.repair_product_id = this.edit_data.repair_product_id;
                this.model.repaired_by = this.edit_data.repaired_by;
                this.model.product_detail_list = this.edit_data.repair_product_profile_details;
                // this.model.product_list =
                //     this.edit_data.repair_product_profiles.map(el => ({
                //         product_id: el.product_id,
                //         serial_no: el.serial_no,
                //         quantity: el.quantity,
                //         warranty_expiry_date: el.warranty_expiry_date,
                //         remarks: rel.remarks
                //     }))
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "REPARI PRODUCT",
                desc: not.text
            });
        }
    }
};
</script>
<style scoped>
textarea::placeholder,
input::placeholder {
    color: #c5c8ce !important;
}
</style>